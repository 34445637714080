import React from 'react';
import { graphql } from 'gatsby';
import { Link } from '@chakra-ui/react';

import Footer from '../../../components/Footer';
import Header from '../../../components/Header';
import Layout from '../../../components/atoms/layout';
import PageTitle from '../../../components/PageTitle';
import { Question } from '../../../template/legal/styles';
import TermOfUseProps from '../../../interface/template';
import { Text2, Text3 } from '../../../components/atoms/body';
import withI18next from '../../../i18n/TemplateI18n';

const PrivacyPolicy: React.SFC<any> = (props: TermOfUseProps) => {
  const { pageContext } = props;
  const { url, pageName } = pageContext;

  return (
    <>
      <Header pageName={pageName} showBackground url={url}>
        <PageTitle subtitle="" title="Privacy Policy" />
      </Header>
      <Layout>
        <Question>
          <Text3 as="p">
            <strong>POLÍTICA DE PRIVACIDAD</strong>
          </Text3>
        </Question>
        <Question>
          <Text2 as="h2">IDENTIFICACION</Text2>
          <Text3 as="p">
            <ul>
              <li>
                <strong>Responsible del Tratamiento</strong>: Ironhack School
                S.L. (en adelante, “Ironhack”)
              </li>
              <li>
                <strong>CIF: </strong>B-86783230
              </li>
              <li>
                <strong>Domicilio social</strong>: C/ CASTELLO, 115, BAJOS 7 -
                28006 MADRID
              </li>
              <li>
                <strong>Delegado de Protección de Datos (DPO)</strong>: El
                Usuario puede contactar con el DPO mediante escrito dirigido al
                domicilio social de Ironhack a la atención del “Delegado de
                Protección de Datos” o a través del correo electrónico{' '}
                <a href="mailto:legal@ironahack.com">legal@ironahack.com</a>
              </li>
            </ul>
          </Text3>
        </Question>
        <Question>
          <Text2 as="h2">INFORMACIN Y CONSENTIMIENTO</Text2>
          <Text3 as="p">
            Mediante la aceptación de la presente Política de Privacidad, el
            Usuario queda informado y presta su consentimiento informado,
            expreso, libre e inequívoco a que los datos personales que facilite
            a través de los formularios de la página Web ubicada en la URL{' '}
            <a href="https://www.ironhack.com/es">www.ironhack.com</a> (en
            adelante, el “Sitio Web”) sean tratados por Ironhack.
          </Text3>
          <Text3 as="p">
            Ironhack no será responsable de los tratamientos de datos que sean
            efectuados por medio de sitios web que no sean responsabilidad de
            Ironhack.
          </Text3>
        </Question>
        <Question>
          <Text2 as="h2">OBLIGATORIEDAD DE FACILITAR LOS DATOS</Text2>
          <Text3 as="p">
            Los datos solicitados en los formularios del Sitio Web son, con
            carácter general, obligatorios (salvo que en el campo requerido se
            especifique lo contrario) para cumplir con las finalidades
            establecidas. Por lo tanto, si no se facilitan o no se facilitan
            correctamente, no podrán atenderse las solicitudes efectuadas por el
            Usuario, sin perjuicio de que pueda visualizarse libremente el
            contenido del Sitio Web.
          </Text3>
        </Question>
        <Question>
          <Text2 as="h2">
            ¿CON QUÉ FINALIDAD TRATARÁ IRONHACK LOS DATOS PERSONALES DEL USUARIO
            Y DURANTE CUÁNTO TIEMPO?
          </Text2>
          <Text3 as="p">
            Los datos personales facilitados por el Usuario{' '}
            <strong>a través del formulario de contacto del Sitio Web</strong>{' '}
            serán tratados por Ironhack conforme a las siguientes finalidades:
          </Text3>
          <Text3 as="p">
            <ul>
              <li>
                Atender consultas, sugerencias y quejas que el Usuario ponga en
                conocimiento de Ironhack. Sus datos serán conservados con ese
                fin durante todo el tiempo de la gestión de su consulta,
                sugerencia y/o queja y, aún después, hasta que prescriban las
                eventuales responsabilidades derivadas de la consulta.
              </li>
            </ul>
            Los datos personales facilitados{' '}
            <strong>
              a través de la suscripción al boletín comercial del Sitio Web{' '}
            </strong>
            serán tratados por Ironhack conforme a las siguientes finalidades:
          </Text3>
          <Text3 as="p">
            <ul>
              <li>
                Llevar a cabo la suscripción al boletín comercial para mantener
                informado al Usuario sobre últimas noticias y eventos de
                Ironhack. Sus datos serán conservados hasta que usted retire el
                consentimiento prestado.
              </li>
            </ul>
            Los datos personales facilitados a través{' '}
            <strong>del formulario de inscripción a cursos de Ironhack</strong>{' '}
            serán tratados por Ironhack conforme a las siguientes finalidades:
          </Text3>
          <Text3 as="p">
            <ul>
              <li>
                Llevar a cabo la gestión de su inscripción, así como las
                acciones necesarias para la selección de los estudiantes. Sus
                datos serán conservados durante todo el tiempo de la gestión de
                su inscripción y, aún después, hasta que prescriban las
                eventuales responsabilidades derivadas de la inscripción.
              </li>
              <li>
                En caso de que haya marcado la casilla correspondiente, llevar a
                cabo la suscripción al boletín comercial para mantenerle
                informado sobre la últimas noticias y eventos de Ironhack. Sus
                datos serán conservados hasta que usted retire el consentimiento
                prestado.
              </li>
            </ul>
            Los datos personales facilitados
            <strong>
              {' '}
              a través del formulario de inscripción como empresa colaboradora
              de Ironhack{' '}
            </strong>
            serán tratados por Ironhack conforme a las siguientes finalidades:
          </Text3>
          <Text3 as="p">
            <ul>
              <li>
                Llevar a cabo la inscripción de la compañía para la que usted
                trabaja como empresa colaboradora de Ironhack. Sus datos serán
                conservados durante todo el tiempo de la gestión de la empresa
                como empresa colaboradora y, aún después, hasta que prescriban
                las eventuales responsabilidades derivadas de la inscripción.
              </li>
            </ul>
            Los datos personales facilitados{' '}
            <strong>
              a través del formulario de inscripción en una oferta de empleo
            </strong>{' '}
            serán tratados por Ironhack conforme a las siguientes finalidades:
          </Text3>
          <Text3 as="p">
            <ul>
              <li>
                Llevar a cabo la valoración de su candidatura para el proceso de
                selección y, cuando sea aplicable, llevar a cabo las acciones
                necesarias para la incorporación en el proceso de selección, así
                como en futuros procesos que puedan ser de su interés. Salvo que
                se indique lo contrario, sus datos serán conservados durante el
                plazo de un año desde su inscripción en la oferta. Por lo tanto,
                es su responsabilidad mantener actualizados dichos datos ya que
                podrán ser utilizados para notificar ofertas de Ironhack que
                puedan ser de su interés.
              </li>
            </ul>
          </Text3>
        </Question>
        <Question>
          <Text2 as="h2">
            ¿QUÉ DATOS DE CARÁCTER PERSONAL TRATARÁ IRONHACK?
          </Text2>
          <Text3 as="p">
            En relación con el formulario de contacto disponible en el Sitio
            Web, Ironhack tratará los siguientes datos:
          </Text3>
          <Text3 as="p">
            <ul>
              <li>Datos de identificación: nombre, apellidos, etc. </li>
              <li>
                Datos de contacto: correo electrónico, número de teléfono, etc.{' '}
              </li>
            </ul>
            En relación con el formulario de suscripción al boletín comercial
            disponible en el Sitio Web, Ironhack tratará los siguientes datos:
          </Text3>
          <Text3 as="p">
            <ul>
              <li>Datos de contacto: correo electrónico, etc. </li>
            </ul>
            En relación con el formulario de inscripción a los cursos de
            Ironhack disponible en el Sitio Web, Ironhack tratará los siguientes
            datos:
          </Text3>
          <Text3 as="p">
            <ul>
              <li>Datos de identificación: nombre, apellidos, etc. </li>
              <li>
                Datos de contacto: correo electrónico, número de teléfono, etc.{' '}
              </li>
            </ul>
            En relación con el formulario de inscripción como empresa
            colaboradora de Ironhack disponible en el Sitio Web, Ironhack
            tratará los siguientes datos:
          </Text3>
          <Text3 as="p">
            <ul>
              <li>Datos de identificación: nombre, apellidos, etc. </li>
              <li>Datos de empleo: posición. </li>
              <li>
                Datos de contacto: correo electrónico, número de teléfono, etc.{' '}
              </li>
            </ul>
            En relación con el formulario de inscripción en una oferta de
            trabajo, Ironhack tratará los siguientes datos:
          </Text3>
          <Text3 as="p">
            <ul>
              <li>
                Datos de identificación: nombre, apellidos, imagen, NIF,
                dirección, etc.{' '}
              </li>
              <li>
                Datos de contacto: número de teléfono, dirección de correo
                electrónico, etc.
              </li>
              <li>
                Datos académicos y profesionales: formación/titulaciones,
                historial de estudiante, experiencia profesional, pertenencia a
                colegios o asociaciones profesionales.
              </li>
              <li>
                Datos de detalles de empleo: profesión, puesto de trabajo, datos
                no económicos de nómina, historial del trabajador.
              </li>
              <li>
                Otros datos que puedan ser incluidos en el Currículum Vitae.
              </li>
            </ul>
            En caso de que el Usuario facilite datos de terceros, manifiesta
            contar con el consentimiento de los mismos y se compromete a
            trasladarle la información contenida en la Política de Privacidad,
            eximiendo a Ironhack de cualquier responsabilidad en este sentido.
            No obstante, Ironhack podrá llevar a cabo las verificaciones
            periódicas para constatar este hecho, adoptando las medidas de
            diligencia debida que correspondan, conforme a la normativa de
            protección de datos.
          </Text3>
        </Question>
        <Question>
          <Text2 as="h2">POLÍTICA DE COOKIES</Text2>
          <Text3 as="p">
            Haga clic{' '}
            <Link href="https://cdn.ironhack.com/marketing/IH_Coookies_Policy.docx">
              aquí
            </Link>{' '}
            para ver nuestra política de cookies
          </Text3>
        </Question>
        <Question>
          <Text2 as="h2">
            ¿CUÁL ES LA LEGITIMACIÓN DEL TRATAMIENTO DE LOS DATOS DE CARÁCTER
            PERSONAL DEL USUARIO?
          </Text2>
          <Text3 as="p">
            <ul>
              <li>
                El tratamiento de los datos relativo a la gestión de las
                consultas, sugerencias y/o quejas se basa en el consentimiento
                prestado por el Usuario en el momento de su solicitud.
              </li>
              <li>
                El tratamiento de datos relativo a la suscripción al boletín
                comercial para poder ser informado de las últimas noticias y
                eventos de Ironhack se basa en el consentimiento prestado por el
                Usuario en el momento de su solicitud.
              </li>
              <li>
                El tratamiento de los datos relativo a la solicitud de
                inscripción en los cursos de Ironhack se basa en la ejecución de
                la relación contractual entre Ironhack y el Usuario por lo que
                no precisa del consentimiento del Usuario.
              </li>
              <li>
                El tratamiento de los datos relativo a la solicitud de
                inscripción como empresa colaboradora de Ironhack se basa en la
                ejecución de la relación contractual por lo que no precisa del
                consentimiento.
              </li>
              <li>
                El tratamiento de los datos relativo a la solicitud de
                inscripción de las ofertas de trabajo se basa en el
                consentimiento del Usuario.
              </li>
            </ul>
            Por favor, tenga en cuenta que podrá retirar el consentimiento en
            cualquier momento y que la retirada del consentimiento no afectará a
            la licitud del tratamiento llevado a cabo con anterioridad a su
            revocación.
          </Text3>
        </Question>
        <Question>
          <Text2 as="h2">DESTINATARIOS</Text2>
          <Text3 as="p">Sus datos podrán ser comunicados a: </Text3>
          <Text3 as="p">
            <ul>
              <li>
                Las Administraciones públicas en los casos previstos por ley.
              </li>
              <li>
                Juzgados y Cuerpos de Seguridad en los casos exigidos por ley.
              </li>
              {/* Removed because of task https://app.clickup.com/t/1qe3yg2*/}
              {/* <li>*/}
              {/*  Servicios para el procesado de la información con fines de*/}
              {/*  marketing:*/}
              {/*  <ul>*/}
              {/*    <li>Mailchimp</li>*/}
              {/*    <li>Pipedrive</li>*/}
              {/*    <li>Google Analytics</li>*/}
              {/*    <li>Hotjar</li>*/}
              {/*    <li>Facebook Custom Audience</li>*/}
              {/*  </ul>*/}
              {/* </li>*/}
            </ul>
          </Text3>
        </Question>
        <Question>
          <Text2 as="h2">TRANSFERENCIAS INTERNACIONALES</Text2>
          <Text3 as="p">
            La información recopilada puede ser transferida a:
          </Text3>
          <Text3 as="p">
            <ul>
              <li>Ironhack campuses en Mexico, U.S. &amp; Brazil</li>
              <li>Mailchimp Inc. (U.S.)</li>
            </ul>
          </Text3>
        </Question>
        <Question>
          <Text2 as="h2">RESPONSABILIDAD DEL USUARIO</Text2>
          <Text3 as="p">El Usuario:</Text3>
          <Text3 as="p">
            <ul>
              <li>
                Garantiza que es mayor de 18 años y que los datos que facilita a
                Ironhack son verdaderos, exactos, completos y actualizados. A
                estos efectos, el Usuario responde de la veracidad de todos los
                datos que comunique y mantendrá convenientemente actualizada la
                información facilitada, de tal forma que responda a su situación
                real.
              </li>
              <li>
                Garantiza que ha informado a los terceros de los que facilite
                sus datos, en caso de hacerlo, de los aspectos contenidos en
                este documento. Asimismo, garantiza que ha obtenido su
                autorización para facilitar sus datos a Ironhack para los fines
                señalados.
              </li>
              <li>
                Será responsable de las informaciones falsas o inexactas que
                proporcione a través del Sitio Web y de los daños y perjuicios,
                directos o indirectos, que ello cause a Ironhack o a terceros.
              </li>
            </ul>
          </Text3>
        </Question>
        <Question>
          <Text2 as="h2">COMUNICACIONES COMERCIALES</Text2>
          <Text3 as="p">
            Una de las finalidades para las que Ironhack trata los datos
            personales proporcionados por parte de los Usuarios es para
            remitirles comunicaciones electrónicas con información relativa a
            productos, servicios, promociones, ofertas, eventos o noticias
            relevantes para los Usuarios. Siempre que se realice alguna
            comunicación de este tipo, ésta será dirigida única y exclusivamente
            a aquellos Usuarios que hubieran manifestado expresamente su
            consentimiento.
          </Text3>
          <Text3 as="p">
            En caso de que el Usuario desee dejar de recibir comunicaciones
            comerciales o promocionales por parte de Ironhack puede solicitar la
            baja del servicio enviando un email a la siguiente dirección de
            correo electrónico:{' '}
            <a href="mailto:legal@ironhack.com">legal@ironhack.com</a>
          </Text3>
        </Question>
        <Question>
          <Text2 as="h2">SUS DERECHOS</Text2>
          <Text3 as="p">
            El Usuario puede ponerse en contacto con Ironhack a través de un
            escrito a la dirección Calle Castello, 115B, Local 7, 28006, Madrid,
            con la Referencia “Protección de Datos” o a través de un correo
            electrónico a la dirección{' '}
            <a href="mailto:yolanda@ironhack.com">yolanda@ironhack.com</a>{' '}
            adjuntando copia de su documento de identidad, en cualquier momento
            y de manera gratuita, para:
          </Text3>
          <Text3 as="p">
            <ul>
              <li>Revocar los consentimientos otorgados. </li>
              <li>
                Obtener confirmación acerca de si en Ironhack se están tratando
                datos personales que conciernen al Usuario o no.{' '}
              </li>
              <li>Acceder a sus datos personales. </li>
              <li>Rectificar los datos inexactos o incompletos. </li>
              <li>Solicitar la supresión de sus datos. </li>
              <li>
                Obtener de Ironhack la limitación del tratamiento de los datos
                cuando se cumpla alguna de las condiciones previstas en la
                normativa de protección de datos.
              </li>
              <li>
                En determinadas circunstancias y por motivos relacionados con su
                situación particular al tratamiento de tus datos (en concreto,
                con fines comerciales por parte de Ironhack), podrá oponerse al
                tratamiento de sus datos. Ironhack dejará de tratar los datos,
                salvo por motivos legítimos imperiosos, o el ejercicio o la
                defensa de posibles reclamaciones.
              </li>
              <li>
                Obtener intervención humana, a expresar tu punto de vista y a
                impugnar las decisiones automatizadas adoptadas por Ironhack.
              </li>
              <li>Solicitar la portabilidad de tus datos. </li>
              <li>
                Interponer una reclamación relativa a la protección de sus datos
                personales ante la autoridad de control correspondiente (en el
                caso de España, la Agencia Española de Protección de Datos).
              </li>
            </ul>
            El ejercicio de los derechos referidos quedará condicionado al
            cumplimiento de los requisitos previstos por la normativa vigente en
            cada momento para su atención.
          </Text3>
        </Question>
        <Question>
          <Text2 as="h2">MEDIDAS DE SEGURIDAD</Text2>
          <Text3 as="p">
            Ironhack tratará los datos del Usuario en todo momento de forma
            confidencial y guardando el preceptivo deber de secreto respecto de
            los mismos, de conformidad con lo previsto en la normativa de
            aplicación, adoptando al efecto las medidas de índole técnica y
            organizativas necesarias que garanticen la seguridad de sus datos y
            eviten su alteración, pérdida, tratamiento o acceso no autorizado,
            habida cuenta del estado de la tecnología, la naturaleza de los
            datos almacenados y los riesgos a que están expuestos.
          </Text3>
          <Text3 as="p">Última actuación: 10/08/2018</Text3>
          <Text3 as="p">
            <strong>
              Copyright © 2018 Ironhack School S.L. Todos los derechos
              reservados.{' '}
            </strong>
          </Text3>
        </Question>
      </Layout>
      <Footer page={pageName} />
    </>
  );
};

export const query = graphql`
  query {
    locales: allLocale(
      filter: {
        lng: { eq: "es" }
        ns: { regex: "/(seo)|(breadcrumbs)|(menu)|(banner)/" }
      }
    ) {
      ...TranslationFragment
    }
  }
`;

export default withI18next('es')(PrivacyPolicy);
